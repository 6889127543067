<template>
    <div class="center-text"> 
        <lay-panel> 
               <div class="loginText">51风流登录口</div>
            <lay-form :model="user" :pane="true">
                <lay-form-item label="账户" prop="userName">
                    <lay-input v-model="user.userName"></lay-input>
                </lay-form-item>
              
                <lay-form-item label="密码" prop="passWord">
                    <lay-input v-model="user.passWord" type="passWord"></lay-input>
                </lay-form-item> 
                <lay-form-item>
                    <lay-button type="primary" @click="submit2">提交</lay-button>
                </lay-form-item>
            </lay-form>

        </lay-panel>

    </div>
</template>
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import router from '../../router'
import { layer } from '@layui/layer-vue'
import {login} from '../../api'
import { setToken,removeToken } from '@/utils/auth'





const user = reactive({
    userName: "",
    passWord: "",
})

const submit2 = function () {
    removeToken();
    
    login(JSON.stringify(user)).then((res: any) => {
        if (res!=""&&res.code == 0) { 

            setToken(res.data);
            layer.msg("登录成功", { icon : 1, time: 1000})
            router.push({ path: '/' }) 
        }  
    })

 
};

</script>

<style >
.loginText{

  font-size: 16px;
  padding: 10px;

}
.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    /* 100% of the viewport height */
}
</style>